import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/gestionRdvVelo";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    set_dispo(dispo, callback) {
        var datas = { dispo };
        axios.post(this.URL + '/v1/set_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_dispo(key, newData, sendEmail, callback) {
        var datas = { key, newData, sendEmail };
        axios.post(this.URL + '/v1/edit_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo(city, agency, callback) {
        var datas = { city, agency };
        axios.post(this.URL + '/v1/get_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo_by_client(client, callback) {
        var datas = { client };
        axios.post(this.URL + '/v1/get_dispo_by_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo_by_agency_between_timestamp(city, agency, start, end, callback) {
        var datas = { city, agency, start, end };
        axios.post(this.URL + '/v1/get_dispo_by_agency_between_timestamp', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo_between_timestamp(start, end, callback) {
        var datas = { start, end };
        axios.post(this.URL + '/v1/get_dispo_between_timestamp', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_rdv_type_liste(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_rdv_type_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency_liste(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_agency_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contract_by_num(num, callback) {
        var datas = { num };
        axios.post(this.URL + '/v1/get_contract_by_num', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contract_materiel_by_id(id, callback) {
        var datas = { id };
        axios.post(this.URL + '/v1/get_contract_materiel_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_dispo(key, callback) {
        var datas = { key };
        axios.post(this.URL + '/v1/delete_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_future_dispo_recurrence(key, start, callback) {
        var datas = { key, start };
        axios.post(this.URL + '/v1/delete_future_dispo_recurrence', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_future_dispo_recurrence_betwenne_date(key, start, end, callback) {
        var datas = { key, start, end };
        axios.post(this.URL + '/v1/delete_future_dispo_recurrence_betwenne_date', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_dispo_recurrence(key, callback) {
        var datas = { key };
        axios.post(this.URL + '/v1/delete_dispo_recurrence', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    save_calendar_colors(colors, callback) {
        let datas = { colors }
        axios.post(this.URL + '/v1/save_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_calendar_colors(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    save_day_to_hide(days, callback) {
        let datas = { days }
        axios.post(this.URL + '/v1/save_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_day_to_hide(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_by_code_client(code_client, callback) {
        let datas = { code_client }
        axios.post(this.URL + '/v1/get_client_by_code_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_by_name_or_code_client(clientData, callback) {
        let datas = { clientData }
        axios.post(this.URL + '/v1/get_client_by_name_or_code_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    validation_dispo_mail(resa, callback) {
        let datas = { resa }
        axios.post(this.URL + '/v1/validation_dispo_mail', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contracts_by_code_client(code, callback) {
        let datas = { code }
        axios.post(this.URL + '/v1/get_contracts_by_code_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    update_contrat_num_by_key(key, contratNum, callback) {
        let datas = { key, contratNum }
        axios.post(this.URL + '/v1/update_contrat_num_by_key', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataApi()